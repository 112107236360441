<template>
    <el-table :data="itemsData">
        <el-table-column label="id" prop="id" width="60" />
        <el-table-column label="Namn" prop="name" />
        <el-table-column label="Registration nr" prop="registrationNumber" />
        <el-table-column label="Status" prop="status" />
        <el-table-column width="50">
            <template slot-scope="scope">
                <router-link :to="'yearEndDisposition/' + scope.row.id">
                    <el-button type="primary" class="p-1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                        </svg>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        itemsData: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
